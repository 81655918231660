import {BehaviorExperimentDetailResponse} from "@sense-os/goalie-js/dist/behaviorExperiment";
import {createAction, ActionType, createAsyncAction} from "typesafe-actions";
import {BEHAVIOUR_EXP_FORM_TYPE, BehaviorExprFormValues} from "./behaviorExprTypes";

export const behaviorExprActions = {
	/**
	 * Form dialog actions
	 */
	openCreateForm: createAction("behaviorExprActions/openCreateForm", (userId: number) => ({
		userId,
	}))(),

	closeForm: createAction("behaviorExprActions/closeForm")(),

	/**
	 * Save behavior experiment draft form values
	 */
	saveFormValues: createAction(
		"behaviorExprActions/saveFormValues",
		(userId: number, formValues?: BehaviorExprFormValues) => ({userId, formValues}),
	)(),

	/**
	 * Save data through api
	 * * `isReplan` is added to the request payload to tracking on the rescheduling behavior experiment
	 */
	saveBehaviorExpr: createAsyncAction(
		"behaviorExprActions/saveBehaviorExpr",
		"behaviorExprActions/saveBehaviorExprSucceed",
		"behaviorExprActions/saveBehaviorExprFailed",
	)<
		{userId?: number; formValues: BehaviorExprFormValues; formType: BEHAVIOUR_EXP_FORM_TYPE},
		{userId?: number},
		Error
	>(),

	/**
	 * Save Behavior Experiment Task
	 */
	saveBehaviorExprTask: createAsyncAction(
		"behaviorExprActions/saveBehaviorExprTask",
		"behaviorExprActions/saveBehaviorExprTaskSucceed",
		"behaviorExprActions/saveBehaviorExprTaskFailed",
	)<
		{
			userId?: number;
			formValues: BehaviorExprFormValues;
			formType: BEHAVIOUR_EXP_FORM_TYPE;
			behaviorExprId?: number;
			taskId?: number;
		},
		{userId?: number},
		Error
	>(),

	/**
	 * Get Behavior Experiment by id
	 */
	getBehaviorExprById: createAsyncAction(
		"behaviorExprActions/fetchBehaviorExprDetail",
		"behaviorExprActions/fetchBehaviorExprDetailSucceed",
		"behaviorExprActions/fetchBehaviorExprDetailFailed",
	)<
		{behaviorExprId: string},
		{behaviorExprId: string; data: BehaviorExperimentDetailResponse},
		{behaviorExprId: string; error: Error}
	>(),

	/**
	 * Plan Again
	 */
	openPlanAgainForm: createAction(
		"behaviorExprActions/openReplanForm",
		(userId: number, behaviorExpDetail: BehaviorExprFormValues) => ({
			userId,
			behaviorExpDetail,
		}),
	)(),

	/**
	 * Edit Modal
	 * set `isDetailModalOpened` to false, then set `isFormOpened` to true
	 */
	openEditForm: createAction(
		"behaviorExprActions/openEditForm",
		(userId: number, behaviorExpDetail: BehaviorExprFormValues, behaviorExpId: number) => ({
			userId,
			behaviorExpDetail,
			behaviorExpId,
		}),
	)(),

	/**
	 * Reset Form value
	 * set the form values to `null` by selected `userId`
	 */
	resetFormValues: createAction("behaviorExprActions/resetFormValues", (userId: number) => ({userId}))(),

	/**
	 * Delete Behavior Experiment
	 */
	deleteBehaviorExperiment: createAsyncAction(
		"behaviorExprActions/deleteBehaviorExpr",
		"behaviorExprActions/deleteBehaviorExprSucceed",
		"behaviorExprActions/deleteBehaviorExprFailed",
	)<{behaviorExprId: string; taskId: number}, void, Error>(),
};

export type BehaviorExprActionType = ActionType<typeof behaviorExprActions>;
