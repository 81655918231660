import produce from "immer";
import {getType} from "typesafe-actions";
import {agoraActions, AgoraActionType} from "./agoraActions";
import {AgoraState} from "./types";
import {extractRoomIdFromText} from "../helpers/meetingHelpers";

const initialState: AgoraState = {
	isAgoraWindowOpen: false,
	windowAction: null,
	createdMeetingLink: null,
	roomId: null,
	joinedTime: null,
	leaveTime: null,
	isInvitationDialogOpen: false,
	invitationLink: null,
	invitationCreator: null,
};

const agoraReducer = produce((state: AgoraState = initialState, action: AgoraActionType): AgoraState => {
	switch (action.type) {
		case getType(agoraActions.openAgoraWindow.request):
			state.windowAction = action.payload.windowAction;
			state.roomId = extractRoomIdFromText(action.payload.meetingText) || null;
			state.createdMeetingLink = null;
			state.joinedTime = null;
			state.leaveTime = null;
			return;
		case getType(agoraActions.openAgoraWindow.success):
			state.isAgoraWindowOpen = true;
			return;
		case getType(agoraActions.resetAgoraWindowState):
			state.isAgoraWindowOpen = false;
			state.windowAction = null;
			state.roomId = null;
			return;
		case getType(agoraActions.openInvitationDialog):
			state.isInvitationDialogOpen = true;
			state.invitationLink = action.payload.invitationLink;
			state.invitationCreator = action.payload.invitationCreator;
			return;
		case getType(agoraActions.closeInvitationDialog):
			state.isInvitationDialogOpen = false;
			state.invitationLink = null;
			state.invitationCreator = null;
			return;
		case getType(agoraActions.userCreatedMeeting):
			state.createdMeetingLink = action.payload.createdLink;
			return;
		case getType(agoraActions.userJoinedMeeting):
			state.joinedTime = action.payload.joinedTime;
			return;
		case getType(agoraActions.userLeaveMeeting):
			state.leaveTime = action.payload.leaveTime;
			return;
	}
}, initialState);

export default agoraReducer;
