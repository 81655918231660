import {AppState} from "redux/AppState";
import {BEHAVIOUR_EXP_FORM_TYPE, BehaviorExprFormValues} from "./behaviorExprTypes";
import {LoadingState} from "constants/redux";
import {BehaviorExperimentDetailResponse} from "@sense-os/goalie-js";

/** Extract `behaviorExperiment` state from AppState */
const getBehaviorExprState = (state: AppState) => state.behaviorExperiment;

/** Get `savingState` from `behaviorExperiment` state */
export const getBehaviorExprSavingState = (state: AppState): LoadingState => getBehaviorExprState(state).savingState;

/** Get `isFormOpened` condition from `behaviorExperiment` state */
export const getIsBehaviorExprFormOpened = (state: AppState): boolean => {
	const {isFormOpened, userId} = getBehaviorExprState(state);

	return isFormOpened && !!userId;
};

/** Get behavior expr saved values by user id from `behaviorExperiment` state */
export const getBehaviorExprSavedValuesByUserId = (state: AppState): BehaviorExprFormValues => {
	const {savedFormValues, userId} = getBehaviorExprState(state);

	return savedFormValues[userId];
};

/**
 * return detail behavior expr
 */
export const getBehaviorExprDetailResponse = (state: AppState): BehaviorExperimentDetailResponse =>
	getBehaviorExprState(state).behaviorExprDetail;

/** get behavior experiment loadingState */
export const getBehaviorExprLoadingState = (state: AppState): LoadingState => {
	return getBehaviorExprState(state).loadingState;
};

/** get behavior experiment isDetailModalOpened status */
export const isBehaviorExprDetailModalOpened = (state: AppState) => {
	return getBehaviorExprState(state).isDetailModalOpened;
};

export const getBehaviourExpFormType = (state: AppState): BEHAVIOUR_EXP_FORM_TYPE =>
	getBehaviorExprState(state).formType;

/** get behavior experiment user id state */
export const getBehaviorExperimentUserId = (state: AppState): number => {
	return getBehaviorExprState(state).userId;
};

/** get behavior experiment user id state */
export const getBehaviorExperimentId = (state: AppState): number => {
	return getBehaviorExprState(state).behaviourExpId;
};

/** get behavior experiment deleting state */
export const getBehaviorExperimentDeletingState = (state: AppState): LoadingState => {
	return getBehaviorExprState(state).deletingState;
};
