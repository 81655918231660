import {createAction, ActionType} from "typesafe-actions";
import moment from "moment";
import {Interval, Sensors, Smq, Omq, SensorDatum} from "redux/tracking/TrackingTypes";
import {RomData} from "./RomTypes";
import {ThunkAction} from "redux-thunk";
import {AppState} from "redux/AppState";
import Localization, {ILocalization} from "../../../localization/Localization";
import {getContactById} from "../../../contacts/redux/contactSelectors";
import {TIME_UNITS} from "constants/time";
import {InternalServerError, ForbiddenError} from "@sense-os/goalie-js";
import {contactActions, ContactActionType} from "../../../contacts/redux/contactAction";
import {toastActions, ToastActionTypes} from "../../../toaster/redux/toastAction";
import {apiCall} from "../../../helpers/apiCall/apiCall";
import {getClientStatus} from "../../../treatmentStatus/redux/treatmentStatusSelectors";
import strTranslation from "../../../assets/lang/strings";
import {getSensorResolved} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {getSessionId} from "../../../auth/helpers/authStorage";

export namespace RomAction {
	/** Indicates that ROM data is being loaded */
	export const loadingRomData = createAction("RomAction/loadingRomData", (userId: number, interval: Interval) => ({
		userId,
		interval,
	}))();
	/** Indicates that ROM data is loaded */
	export const loadedRomData = createAction(
		"RomAction/loadedRomData",
		(userId: number, interval: Interval, data: RomData) => ({userId, interval, data}),
	)();
	/** Indicates that there is a failure in loading ROM data */
	export const errorLoadingRomData = createAction(
		"RomAction/errorLoadingRomData",
		(userId: number, interval: Interval, err: any) => ({userId, interval, err}),
	)();
	/** Set the interval of rom data for portal to show in the rom chart */
	export const setCurrentInterval = createAction(
		"RomAction/setCurrentInterval",
		(userId: number, interval: Interval) => ({userId, interval}),
	)();

	const combinedActions = {
		loadingRomData,
		loadedRomData,
		errorLoadingRomData,
		setCurrentInterval,
	};

	export type RomActionType = ActionType<typeof combinedActions>;
	export type RomThunkActionType = ThunkAction<
		void,
		AppState,
		any,
		RomActionType | ContactActionType | ToastActionTypes
	>;

	//
	// THUNK Actions
	//

	/**
	 * Change treatment interval by userId. Also load ROM data by interval
	 *
	 * @param {number} userId
	 */
	export function changeInterval(userId: number): RomThunkActionType {
		return (dispatch, getState) => {
			const {start, end} = getTreatmentInterval(getState(), userId);

			const interval: Interval = {
				id: `${start.getTime()}_${end.getTime()}`,
				start,
				end,
				range: null,
			};

			dispatch(setCurrentInterval(userId, interval));
			dispatch(loadRomData(userId, interval));
		};
	}

	/**
	 * Load ROM data by userId and interval
	 *
	 * ROM data is gathered by fetching OMQ and SMQ sensors by start time and end time.
	 */
	export function loadRomData(userId: number, interval: Interval): RomThunkActionType {
		const loc: ILocalization = Localization;

		return async (dispatch) => {
			dispatch(loadingRomData(userId, interval));
			const token: string = getSessionId();

			const start = moment(interval.start);
			// TODO: For now we don't have a time navigation where therapist
			// can navigate between time range. If there is such a feature,
			// please change this value to `interval.end`
			const end = moment(start).add(3, TIME_UNITS.YEARS);

			//////////////////////////////////////////////////////////////////////////////////////////
			// TRY/CATCH: BEGIN
			let romData: SensorDatum<Smq | Omq>[] = [];
			try {
				romData = await apiCall(
					getSensorResolved,
					token,
					userId,
					[Sensors.SMQ, Sensors.OMQ],
					new Date(start.toISOString()),
					new Date(end.toISOString()),
				);
			} catch (err) {
				dispatch(errorLoadingRomData(userId, interval, err));

				if (err instanceof InternalServerError) {
					// Something wrong with the BE, show error message
					const toastMessage = loc.formatMessage(
						strTranslation.COMMON.toast.error.internal_server_error.cannot_load_data,
					);
					dispatch(toastActions.addToast({message: toastMessage, type: "error"}));
				} else if (err instanceof ForbiddenError) {
					dispatch(contactActions.loadContactById(userId));
				}
				return;
			}
			// TRY/CATCH: END
			//////////////////////////////////////////////////////////////////////////////////////////

			if (romData.length > 0) {
				// Here we try to adjust the end time of the interval
				// if the latest data is greater than the default end time of interval

				const sortedRomData = romData.map((data) => data.endTime.getTime()).sort();
				const latestRomData = sortedRomData[sortedRomData.length - 1];

				if (moment(latestRomData).isAfter(interval.end)) {
					// Add three weeks just to add space to the graph
					const adjustedEndInterval = moment(latestRomData)
						.add(3, TIME_UNITS.WEEKS)
						.endOf(TIME_UNITS.WEEKS)
						.toDate();
					dispatch(
						setCurrentInterval(userId, {
							...interval,
							end: adjustedEndInterval,
						}),
					);
				}
			}

			dispatch(loadedRomData(userId, interval, romData));
		};
	}

	//
	// TREATMENT INTERVAL
	//

	type TreatmentInterval = {start: Date; end: Date};

	/**
	 * Returns start and end time of user's ongoing treatment in NiceDay
	 *
	 * @param {AppState} state
	 * @param {number} userId
	 */
	function getTreatmentInterval(state: AppState, userId: number): TreatmentInterval {
		const interval = getTreatmentIntervalFromClientStatus(state, userId);

		return interval || getTreatmentIntervalFromClientNetwork(state, userId);
	}

	/**
	 * Returns treatment interval, starting from when the logged in user and the client connected for the first time
	 * added by 20 weeks (Common max treatment time for a client, could be more but unlikely)
	 */
	function getTreatmentIntervalFromClientNetwork(state: AppState, userId: number): TreatmentInterval {
		const userContactData = getContactById(state, userId);
		if (!userContactData) {
			return null;
		}

		// Try to find the lowest date value
		let start = moment().startOf(TIME_UNITS.WEEKS);
		if (userContactData.connectedSince && start.isSameOrAfter(userContactData.connectedSince)) {
			start = moment(userContactData.connectedSince).startOf(TIME_UNITS.WEEKS);
		}

		// We only need to show at most one year since the beginning of client treatment
		// TODO: Change this in the future
		const end = moment(start).add(20, TIME_UNITS.WEEKS).endOf(TIME_UNITS.WEEKS);

		return {
			start: start.toDate(),
			end: end.toDate(),
		};
	}

	/**
	 * Returns treatment interval based on client's treatment status.
	 * Returns `null` if therapist hasn't set the treatment for the client yet.
	 */
	function getTreatmentIntervalFromClientStatus(state: AppState, userId: number): TreatmentInterval | null {
		const treatmentStatus = getClientStatus(userId)(state);

		if (treatmentStatus) {
			// Default treatment end time is 1 week starting from start time.
			let end: Date = moment(treatmentStatus.startTime).endOf(TIME_UNITS.WEEKS).add(1, TIME_UNITS.WEEKS).toDate();

			if (treatmentStatus.endTime) {
				// Use the highest end time value between default end time and the actual treatment status end time.
				const maxEndtime = Math.max(end.valueOf(), treatmentStatus.endTime.valueOf());
				end = moment(maxEndtime).endOf(TIME_UNITS.WEEKS).toDate();
			}

			return {
				start: moment(treatmentStatus.startTime).startOf(TIME_UNITS.WEEKS).toDate(),
				end,
			};
		}

		return null;
	}
}
