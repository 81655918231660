import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {BEHAVIOUR_EXP_FORM_TYPE, BehaviorExprState} from "./behaviorExprTypes";
import {BehaviorExprActionType, behaviorExprActions} from "./behaviorExprActions";

const initialState: BehaviorExprState = {
	/**
	 * Flag to marks if user want to create a new event based on cancelled event.
	 */
	isFormOpened: false,
	/**
	 * To determine saving data request to backend
	 */
	savingState: LoadingState.EMPTY,
	/**
	 * Saved planned event form values keyed by `userId`
	 */
	savedFormValues: {},
	/**
	 * Flag to mark fetching status for behavior experiment detail
	 */
	loadingState: LoadingState.EMPTY,
	/**
	 * Flag to mark if the detail modal open or not
	 */
	isDetailModalOpened: false,
	/**
	 * Init selected user id
	 */
	userId: null,
	/**
	 * Init deleting state
	 */
	deletingState: LoadingState.EMPTY,
	/**
	 * Init behaviorExprDetail
	 */
	behaviorExprDetail: null,
	/**
	 * Init behaviour Exp form type
	 */
	formType: null,
	/**
	 * Init behaviour Exp id
	 */
	behaviourExpId: null,
};

export function behaviorExprReducer(
	state: BehaviorExprState = initialState,
	action: BehaviorExprActionType,
): BehaviorExprState {
	switch (action.type) {
		case getType(behaviorExprActions.openCreateForm): {
			return {
				...state,
				isFormOpened: true,
				userId: action.payload.userId,
				formType: BEHAVIOUR_EXP_FORM_TYPE.CREATING,
			};
		}
		case getType(behaviorExprActions.closeForm): {
			return {
				...state,
				isFormOpened: false,
				savingState: LoadingState.EMPTY,
				userId: null,
			};
		}
		case getType(behaviorExprActions.saveFormValues): {
			return {
				...state,
				savedFormValues: {
					...state.savedFormValues,
					[action.payload.userId]: action.payload.formValues,
				},
			};
		}
		case getType(behaviorExprActions.saveBehaviorExprTask.request):
		case getType(behaviorExprActions.saveBehaviorExpr.request): {
			return {
				...state,
				savingState: LoadingState.LOADING,
			};
		}
		case getType(behaviorExprActions.saveBehaviorExprTask.success):
		case getType(behaviorExprActions.saveBehaviorExpr.success): {
			return {
				...state,
				savingState: LoadingState.LOADED,
				savedFormValues: {
					...state.savedFormValues,
					[action.payload.userId]: null,
				},
			};
		}
		case getType(behaviorExprActions.saveBehaviorExprTask.failure):
		case getType(behaviorExprActions.saveBehaviorExpr.failure): {
			return {
				...state,
				savingState: LoadingState.ERROR,
			};
		}
		case getType(behaviorExprActions.getBehaviorExprById.request): {
			return {
				...state,
				loadingState: LoadingState.LOADING,
				// set the behavior experiment detail to null to make sure that while the behavior experiment starts to request
				behaviorExprDetail: null,
			};
		}
		case getType(behaviorExprActions.getBehaviorExprById.success): {
			return {
				...state,
				behaviorExprDetail: action.payload.data,
				loadingState: LoadingState.LOADED,
			};
		}
		case getType(behaviorExprActions.getBehaviorExprById.failure): {
			return {
				...state,
				loadingState: LoadingState.ERROR,
			};
		}
		case getType(behaviorExprActions.openEditForm): {
			const {userId, behaviorExpDetail, behaviorExpId} = action.payload;

			return {
				...state,
				isDetailModalOpened: false,
				isFormOpened: true,
				userId: userId,
				behaviourExpId: behaviorExpId,
				formType: BEHAVIOUR_EXP_FORM_TYPE.EDITING,
				savedFormValues: {
					...state.savedFormValues,
					[userId]: behaviorExpDetail,
				},
			};
		}
		case getType(behaviorExprActions.resetFormValues): {
			return {
				...state,
				savedFormValues: {
					...state.savedFormValues,
					[action.payload.userId]: null,
				},
			};
		}
		case getType(behaviorExprActions.openPlanAgainForm): {
			const userId: number = action.payload.userId;
			const behaviorExpDetail = action.payload.behaviorExpDetail;

			return {
				...state,
				isDetailModalOpened: false,
				isFormOpened: true,
				userId,
				formType: BEHAVIOUR_EXP_FORM_TYPE.PLAN_AGAIN,
				savedFormValues: {
					...state.savedFormValues,
					[userId]: behaviorExpDetail,
				},
			};
		}
		case getType(behaviorExprActions.deleteBehaviorExperiment.request): {
			return {
				...state,
				deletingState: LoadingState.LOADING,
			};
		}
		case getType(behaviorExprActions.deleteBehaviorExperiment.success): {
			return {
				...state,
				deletingState: LoadingState.LOADED,
			};
		}
		case getType(behaviorExprActions.deleteBehaviorExperiment.failure): {
			return {
				...state,
				deletingState: LoadingState.ERROR,
			};
		}
		default:
			return state;
	}
}
