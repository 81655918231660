import {TIME} from "constants/time";
import {AppState} from "redux/AppState";
import {createSelector} from "reselect";

const getAgoraState = (state: AppState) => state.agora;

export const getAgoraWindowAction = createSelector(getAgoraState, (state) => state.windowAction);

export const getAgoraMeetingDuration = createSelector(getAgoraState, (state) =>
	Math.floor(((state.leaveTime || Date.now()) - state.joinedTime) / TIME.MILLISECONDS_IN_SECOND),
);

export const getAgoraRoomId = createSelector(getAgoraState, (state) => state.roomId);

export const isInvitationDialogOpen = createSelector(getAgoraState, (state) => state.isInvitationDialogOpen);

export const getInvitationLink = createSelector(getAgoraState, (state) => state.invitationLink);

export const getInvitationCreator = createSelector(getAgoraState, (state) => state.invitationCreator);

export const isAgoraWindowOpened = createSelector(getAgoraState, (state) => state.isAgoraWindowOpen);
